<template>
  <div class="all-height d-flex justify-center">
    <div class="all-width">
      <div class="d-flex align-center">
        <v-spacer></v-spacer>
        <v-btn class="shadow-off mb-4" text @click="refreshData()">
          <v-icon left>mdi-refresh</v-icon>Refresh
        </v-btn>
      </div>
      <v-row>
        <template v-if="listdata.length > 0">
          <v-col cols="12" sm="6" md="4" v-for="(v, k) in listdata" :key="k">
            <v-card link @click="$nova.gotoLink({ path: '/pbc/project/view/' + v._id })">
              <v-card-text class="pa-0">
                <div class="d-flex align-center px-4 pt-2">
                  <div class="subtitle-1 font-weight-bold" style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">
                    {{ v.name || "No name" }}
                  </div>
                  <v-spacer></v-spacer>
                  <v-btn icon @click.stop=" addeditproject = { ...v }; editprojectdialog = true; addeditprojecterror = {};">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </div>
                <div class=" pb-3 px-4 d-flex align-center grey--text text--darken-2">
                  <span class="pr-2 font-weight-bold">Due:</span>
                  <span>{{ $nova.formatDate(v.duedate) }}</span>
                  <div v-if="v.stage !== 1">
                    <v-icon>mdi-circle-small</v-icon>
                    <span label v-if="(Math.floor((new Date(v.duedate) - new Date())/(60*60*24*1000)) < 0)" class="error--text">{{( Math.floor((new Date(v.duedate) - new Date())/(60*60*24*1000))*-1)}} days overdue</span>
                    <span label v-else class="info--text">{{( Math.floor((new Date(v.duedate) - new Date())/(60*60*24*1000)))}} days remaining</span>
                  </div>
                  <v-spacer></v-spacer>
                  <v-chip small label text v-if="v.stage === 0" class="body-2" color="info">Open</v-chip>
                  <v-chip small label v-else-if="v.stage === 1" class="body-2" color="success">Closed</v-chip>
                </div>
                <div class="py-1 px-4 grey lighten-3 d-flex align-center">
                  <span>Total Requests</span>
                  <v-spacer></v-spacer>
                  <span class="title pr-2">{{
                  ((v.summary || {}).requested || 0) +
                    ((v.summary || {}).approved || 0) +
                    ((v.summary || {}).cancelled || 0) +
                    ((v.summary || {}).pending || 0)
                  }}</span>
                </div>
                <div class="py-3 px-4">
                  <div v-if="
                    ((v.summary || {}).requested || 0) +
                    ((v.summary || {}).pending || 0) +
                    ((v.summary || {}).approved || 0) +
                    ((v.summary || {}).cancelled || 0) >
                    0
                  ">
                    <canvas :ref="'pbc' + v._id" style="max-height: 120px"></canvas>
                  </div>
                  <div v-else class="py-5 text-center">
                    <div class="grey--text text--lighten-1 title pb-2">
                      No Request added
                    </div>
                    <v-btn color="primary" small @click.stop="
                      $nova.gotoLink({
                        path: '/pbc/project/view/' + v._id,
                        hash: 'add',
                      })
                    ">
                      <v-icon left>mdi-plus</v-icon>Add Request
                    </v-btn>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </template>

        <template v-else-if="loading">
          <v-col cols="12" sm="6" md="4">
            <v-skeleton-loader type="article, actions"></v-skeleton-loader>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-skeleton-loader type="article, actions"></v-skeleton-loader>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-skeleton-loader type="article, actions"></v-skeleton-loader>
          </v-col>
        </template>

        <v-col cols="12" sm="6" md="4" v-else>
          <v-card>
            <v-card-text class="text-center my-5">
              <div class="grey--text title my-5">No Projects added yet</div>
              <div class="">
                <v-btn color="primary" @click="$nova.gotoLink({ hash: '#add' })">Create new Project</v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-dialog v-model="addprojectdialog" max-width="600" persistent>
          <v-card :loading="addprojectloading" :disabled="addprojectloading" loader-height="2" class="">
            <v-card-text class="primary white--text d-flex align-center py-2 px-4">
              <span class="subtitle-1 white--text">Create New Project</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="addprojectdialog = false" class="ml-2 white--text">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-text>
            <v-card-text class="mt-4 pt-4">
              <v-row>
                <v-col cols="12" class="my-0 py-1">
                  <lb-string v-model="addeditproject.name" label="Name" :error="addeditprojecterror.name" />
                </v-col>
                <v-col cols="6" class="my-0 py-1">
                  <lb-date v-model="addeditproject.duedate" label="Due Date" :error="addeditprojecterror.duedate"
                    :min="$nova.formatDateISO(new Date())" />
                </v-col>
                <v-col cols="6" class="my-0 py-1">
                  <lb-number v-model="addeditproject.reminderdays" min="1" :value="addeditproject.reminderdays || '7'"
                    label="Reminder Frequency (Days)" :error="addeditprojecterror.reminderdays" />
                </v-col>
                <v-col cols="12" class="my-0 py-1">
                  <lb-dropdown label="Associate User(s)" multiple v-model="addeditproject.userlist"
                    :error="addeditprojecterror.userlist" :items="formdata.users" itemtext="name" itemvalue="_id" />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text class="d-flex">
              <v-spacer></v-spacer>
              <v-btn small color="primary" @click="addProject()">Create</v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="editprojectdialog" max-width="600" persistent>
          <v-card :loading="editprojectloading" :disabled="editprojectloading" loader-height="2" class="">
            <v-card-text class="primary white--text d-flex align-center py-2 px-4">
              <span class="subtitle-1 white--text">Edit Project</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="editprojectdialog = false" class="ml-2 white--text">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-text>
            <v-card-text class="mt-4 pt-4">
              <v-row>
                <v-col cols="12" class="my-0 py-1">
                  <lb-string v-model="addeditproject.name" label="Name" :error="addeditprojecterror.name" />
                </v-col>
                <v-col cols="6" class="my-0 py-1">
                  <lb-date v-model="addeditproject.duedate" label="Due Date" :error="addeditprojecterror.duedate"
                    :min="$nova.formatDateISO(new Date())" />
                </v-col>
                <v-col cols="6" class="my-0 py-1">
                  <lb-number v-model="addeditproject.reminderdays" :value="addeditproject.reminderdays || '7'"
                    label="Reminder Frequency (Days)" :error="addeditprojecterror.reminderdays" />
                </v-col>
                <v-col cols="12" class="my-0 py-1">
                  <lb-dropdown label="Associate User(s)" multiple v-model="addeditproject.userlist"
                    :error="addeditprojecterror.userlist" :items="formdata.users" itemtext="name" itemvalue="_id" />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text class="d-flex">
              <v-spacer></v-spacer>
              <v-btn small color="primary" @click="editProject()">Update</v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import pageHeader from '@/components/pageHeader'
// import managelist from '@/components/ManageList'
import {
  Chart,
  PolarAreaController,
  RadialLinearScale,
  ArcElement,
  Legend,
  Title,
  Tooltip,
  DoughnutController,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
Chart.register(
  PolarAreaController,
  RadialLinearScale,
  ArcElement,
  Legend,
  Title,
  Tooltip,
  DoughnutController,
  ChartDataLabels
);

export default {
  name: "pbc_project_list",
  // props: ['props'],
  data: function () {
    return {
      pageprops: {
        name: "Projects",
        breadcrumbs: [],
      },
      addeditproject: {},
      addeditprojecterror: {},
      addprojectdialog: false,
      formdata: {},
      addprojectloading: false,
      editprojectdialog: false,
      editprojectloading: false,
      loading: false,
      listdata: [],
      charts: [],
      chartdata: {},
    };
  },
  // components: {
  //   // pageHeader,managelist
  // },
  created() {
    this.activeMethods();
  },
  activated() {
    this.activeMethods();
  },
  methods: {
    activeMethods() {
      if (this.$route.hash === "#add") {
        this.addeditproject = {};
        this.addeditprojecterror = {};
        this.addprojectdialog = true;
      }
      this.refreshData();
    },
    refreshData() {
      this.charts = [];
      this.chartdata = {};
      this.listdata = [];
      this.getData();
    },
    getData() {
      this.loading = true;
      return this.axios
        .post("/v2/pbc/project/getformdata/")
        .then((dt) => {
          if (dt.data.status === "success") {
            this.formdata = dt.data.data[0] || {};
          } else throw new Error("error reading form data");
          return this.axios.post("/v2/pbc/project/get");
        })
        .then((dt) => {
          this.listdata = [];
          if (dt.data.status === "success" && dt.data.data.length > 0) {
            this.listdata = dt.data.data;
            this.setChart();
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getPBCChartData: function () {
      return {
        type: "doughnut",
        data: {
          labels: [
            "Yet to Respond",
            "Waiting for Approval",
            "Closed",
            "Cancelled",
          ],
          datasets: [{
            label: "Count",
            data: [],
            backgroundColor: [],
            // datalabels: {
            //   anchor: 'center',
            //   backgroundColor: null,
            //   borderWidth: 0
            // },
          },],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "right",
            },
            datalabels: {
              color: "white",
              display: function (context) {
                let dataset = context.dataset;
                let count = dataset.data.length;
                let value = dataset.data[context.dataIndex];
                return (value / count || 0) > 0.1;
              },
              font: {
                weight: "bold",
              },
            },
          },
        },
      };
    },
    setChart: function () {
      this.$nextTick(function () {
        for (let i = 0; i < this.listdata.length; i++) {
          const el = {
            ...this.listdata[i]
          };
          if (
            ((el.summary || {}).requested || 0) +
            ((el.summary || {}).pending || 0) +
            ((el.summary || {}).approved || 0) +
            ((el.summary || {}).cancelled || 0) >
            0
          ) {
            let canvasid = "pbc" + el._id;
            if (this.charts[canvasid]) this.charts[canvasid].destroy();
            this.chartdata[canvasid] = this.getPBCChartData();
            this.chartdata[canvasid].data.datasets = [{
              label: "Count",
              data: [
                (el.summary || {}).requested || 0,
                (el.summary || {}).pending || 0,
                (el.summary || {}).approved || 0,
                (el.summary || {}).cancelled || 0,
              ],
              backgroundColor: this.$nova.getColors(5, 0.5).slice(1,5),
            },];
            this.charts[canvasid] = new Chart(
              this.$refs[canvasid][0].getContext("2d"),
              this.chartdata[canvasid]
            );
          }
        }
      });
    },
    addProject() {
      this.addprojectloading = true;
      this.axios
        .post("/v2/pbc/project/add", {
          data: this.addeditproject
        })
        .then((dt) => {
          if (dt.data.status === "success") {
            this.$store.commit("sbSuccess", "New Project Created");
            if (dt.data.data.length > 0) {
              this.$nova.gotoLink({
                path: "/pbc/project/view/" + dt.data.data[0]._id,
              });
            } else this.refreshData();
          } else {
            if (dt.data.data.length > 0) {
              if (dt.data.data[0].index0)
                this.addeditprojecterror = dt.data.data[0].index0;
            } else throw new Error(dt.data.message);
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.addprojectloading = false;
        });
    },
    editProject() {
      this.editprojectloading = true;
      this.axios
        .post("/v2/pbc/project/edit/" + this.addeditproject._id, {
          data: this.addeditproject,
        })
        .then((dt) => {
          if (dt.data.status === "success") {
            this.$store.commit("sbSuccess", "Project Updated");
            this.editprojectdialog = false;
            this.refreshData();
          } else {
            if (dt.data.data.length > 0) {
              if (dt.data.data[0].index0)
                this.addeditprojecterror = dt.data.data[0].index0;
            } else throw new Error(dt.data.message);
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.editprojectloading = false;
        });
    },
  },
  watch: {
    "$store.state.client": function () {
      this.refreshData();
    },
    $route: function (d) {
      if (d.hash === "#add") {
        this.addprojectdialog = true;
        this.addeditproject = {};
        this.addeditproject.reminderdays =
          this.addeditproject.reminderdays ?? 7;
      }
    },
    addprojectdialog: function (d) {
      if (!d) this.$nova.gotoLink({
        hash: ""
      });
    },
  },
};
</script>
